<template>
  <div class="iframe-container">
    <div class="url-box">
    <p class="url"> <span class="url-title">Url:</span> {{ url }}</p>
  </div>
    <iframe :src="url" frameborder="0"></iframe>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Iframe",
  data() {
    return {
      url: "",
    };
  },
  created() {
    const url = this.$route.params.url;
    axios
      .get(`http://localhost:8080/iframe`)
      .then((response) => {
        console.log(response.data);
        this.url = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
.iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
}

.url-box {
  padding: 15px;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  word-wrap: break-word;
}

.url {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
</style>
